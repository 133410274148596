import Section from '../../Section';
import SectionTitle from '../../SectionTitle';
import NotesField from './NotesField';
import NotesTimeline from './NotesTimeline';
import { addUnisonProjectNote as dispatchAddUnisonProjectNote } from '@/store/slices/unisonProjectNotesSlice';
import { useAppState } from '@/providers/AppStateProvider';
import {
  useDispatch,
  useNotesFromUnisonProjectID,
  usePracticeUsers,
} from '@/store/useStore';
import { useToggle } from '@/hooks/useToggle';
import { classes, createProjectNote } from './utils';

export default function TimelineSection() {
  const dispatch = useDispatch();
  const { onApiError, activeProjectID } = useAppState();
  const notes = useNotesFromUnisonProjectID(activeProjectID);
  const practiceUsers = usePracticeUsers();
  const [isNewNoteShown, toggleNewNote] = useToggle(false);
  const [isSavingNote, toggleSavingNote] = useToggle(false);

  const handleSaveNote = async ({ newNote }: { newNote: string }) => {
    try {
      toggleSavingNote(true);
      const projectNote = await createProjectNote(activeProjectID!, newNote);
      dispatch(dispatchAddUnisonProjectNote(projectNote));
      toggleSavingNote(false);
      toggleNewNote(false);
    } catch (err) {
      toggleSavingNote(false);
      onApiError(err, 'Error saving note');
    }
  };

  const renderContent = () => {
    if (!notes || notes.length === 0) {
      return <div className={classes.message}>No notes for current case.</div>;
    }
    return <NotesTimeline notes={notes} />;
  };

  if (!activeProjectID) return null;

  return (
    <Section className={classes.section}>
      <SectionTitle
        title="Timeline"
        newLabel="Add note"
        onAddNew={() => toggleNewNote(true)}
      />
      {isNewNoteShown && (
        <NotesField
          isSaving={isSavingNote}
          name="newNote"
          users={practiceUsers.map(({ user }) => user)}
          onSave={handleSaveNote}
          onCancel={() => toggleNewNote(false)}
        />
      )}
      {renderContent()}
    </Section>
  );
}
