export const classes = {
  wrapper: 'text-grey-8 text-sm whitespace-pre-wrap',
  mentionsWrapper: 'leading-6',
  mention: 'bg-grey-3 px-2 py-0.5 rounded-lg mx-1',
};

export type Mention = {
  label: string;
  value: number;
};

export function parseMentions(input: string): (string | Mention)[] {
  const regex = /@\[(.+?)]\((\d+)\)/g;
  let result: (string | Mention)[] = [];
  let lastIndex = 0;
  let match;

  while ((match = regex.exec(input)) !== null) {
    if (match.index > lastIndex) {
      result.push(input.substring(lastIndex, match.index));
    }
    result.push({ label: `@${match[1]}`, value: parseInt(match[2]) });
    lastIndex = regex.lastIndex;
  }

  if (lastIndex < input.length) {
    result.push(input.substring(lastIndex));
  }

  return result;
}
