import { useState } from 'react';
import {
  useActiveAccount,
  useDispatch,
  usePracticeProperties,
} from '@/store/useStore';
import { addLead as dispatchAddLead } from '@/store/slices/leadsSlice';
import Dialog, {
  DefaultDialogActions,
  DialogBody,
  DialogTitle,
} from '@/components/Dialog';
import TextField from '@/components/TextField';
import { API } from '@/api';
import { showErrorToaster, showSuccessToaster } from '@/utils/toasters';
import { useAppState } from '@/providers/AppStateProvider';
import { useToggle } from '@/hooks/useToggle';
import type { DecoratedLead } from '@witmetrics/api-client';
import { buildNewLeadProperties, classes, formatSelectValue } from './utils';

export type LeadDialogProps = {
  initialFirstname?: string;
  onSelect?: (lead: DecoratedLead) => void;
  onClose: () => void;
};

export default function NewLeadDialog({
  initialFirstname = '',
  onSelect,
  onClose,
}: LeadDialogProps) {
  const dispatch = useDispatch();
  const { onApiError } = useAppState();
  const practiceProperties = usePracticeProperties();
  const activeAccount = useActiveAccount();
  const [firstname, setFirstname] = useState(initialFirstname);
  const [lastname, setLastname] = useState('');
  const [externalRef, setExternalRef] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [enquiryStatus, setEnquiryStatus] = useState(
    formatSelectValue('Treatment accepted')
  );
  const [invalidFields, setInvalidFields] = useState<string[]>([]);
  const [isSaving, toggleSaving] = useToggle(false);

  const handleSave = async () => {
    try {
      toggleSaving(true);
      if (firstname.trim().length === 0) {
        toggleSaving(false);
        return setInvalidFields(['firstname']);
      }
      if (email.trim().length > 0) {
        const emailData = await API.Users.validateEmail(email);
        if (!emailData.isValid) {
          showErrorToaster(emailData.message);
          toggleSaving(false);
          return setInvalidFields(['email']);
        }
      }
      return handleCreateLead();
    } catch (err) {
      toggleSaving(false);
      onApiError(err, 'Error saving lead', () => handleSave());
    }
  };

  const getLeadProperties = () => {
    return buildNewLeadProperties({ practiceProperties, phone, enquiryStatus });
  };

  const handleCreateLead = async () => {
    try {
      if (!activeAccount) return;
      const leadProperties = getLeadProperties();
      const newLead = await API.Leads.createNewLead({
        firstname,
        lastname,
        email,
        externalRef,
        properties: leadProperties,
        practiceID: activeAccount.accountID,
      });
      dispatch(dispatchAddLead(newLead));
      showSuccessToaster('Lead created');
      toggleSaving(false);
      if (onSelect) onSelect(newLead);
      onClose();
    } catch (err) {
      toggleSaving(false);
      onApiError(err, 'Error creating lead', () => handleCreateLead());
    }
  };

  const isSaveDisabled = () => {
    return isSaving || firstname.trim().length === 0;
  };

  return (
    <Dialog
      className={classes.dialog}
      onClose={() => null /* Prevent accidentally closing it */}>
      <DialogTitle title="New Lead" onClose={onClose} />
      <DialogBody className={classes.body}>
        <div className={classes.section}>
          <TextField
            autoFocus
            error={invalidFields.includes('firstname')}
            className={classes.input}
            name="firstname"
            label="First name"
            value={firstname}
            onChange={(update) => setFirstname(update.firstname)}
          />
          <div className={classes.optional}>Optional fields</div>
          <TextField
            error={invalidFields.includes('lastname')}
            className={classes.input}
            name="lastname"
            label="Last name"
            value={lastname}
            onChange={(update) => setLastname(update.lastname)}
          />
          <TextField
            error={invalidFields.includes('externalRef')}
            className={classes.input}
            name="externalRef"
            label="External Ref."
            value={externalRef}
            onChange={(update) => setExternalRef(update.externalRef)}
          />
          <TextField
            error={invalidFields.includes('email')}
            className={classes.input}
            name="email"
            label="Email"
            value={email}
            onChange={(update) => setEmail(update.email)}
          />
          <TextField
            error={invalidFields.includes('phone')}
            className={classes.input}
            name="phone"
            label="Phone"
            value={phone}
            onChange={(update) => setPhone(update.phone)}
          />
        </div>
      </DialogBody>
      <DefaultDialogActions
        disabled={isSaveDisabled()}
        pending={isSaving}
        confirmLabel="Save"
        pendingLabel="Saving..."
        onCancel={onClose}
        onConfirm={handleSave}
      />
    </Dialog>
  );
}
