import Dialog, {
  DefaultDialogActions,
  DialogBody,
  DialogTitle,
} from '@/components/Dialog';
import { classes } from './utils';

export type NoFilesWarningDialogProps = {
  onContinue: () => void;
  onClose: () => void;
};

export default function NoFilesWarningDialog({
  onContinue,
  onClose,
}: NoFilesWarningDialogProps) {
  return (
    <Dialog className={classes.dialog} onClose={onClose}>
      <DialogTitle title="No files selected" onClose={onClose} />
      <DialogBody className={classes.body}>
        <p className={classes.paragraph}>
          Continue without selecting any files?
        </p>
      </DialogBody>
      <DefaultDialogActions
        confirmLabel="Continue"
        onCancel={onClose}
        onConfirm={onContinue}
      />
    </Dialog>
  );
}
