import { type NoteComment } from '@witmetrics/api-client';
import { useDispatch, usePracticeUsers } from '@/store/useStore';
import {
  deleteNoteComment as dispatchDeleteNoteComment,
  updateNoteComment as dispatchUpdateNoteComment,
} from '@/store/slices/noteCommentsSlice';
import ListItem from '@mui/material/ListItem';
import { useToggle } from '@/hooks/useToggle';
import { API } from '@/api';
import { useAppState } from '@/providers/AppStateProvider';
import BlockHeader from '../../BlockHeader';
import NotesField from '../../../../NotesField';
import Note from '@/components/Note';
import { classes, parseContent, styles, updateNoteComment } from './utils';

export type CommentProps = {
  editable?: boolean;
  comment: NoteComment;
};

export default function Comment({ editable, comment }: CommentProps) {
  const dispatch = useDispatch();
  const { onApiError } = useAppState();
  const practiceUsers = usePracticeUsers();
  const [isEditing, toggleEditing] = useToggle(false);
  const [isSaving, toggleSaving] = useToggle(false);
  const [isDeleting, toggleDeleting] = useToggle(false);

  const handleSave = async ({ message }: { message: string }) => {
    try {
      toggleSaving(true);
      const updatedComment = await updateNoteComment(comment.id, message);
      dispatch(dispatchUpdateNoteComment(updatedComment));
      toggleSaving(false);
      toggleEditing(false);
    } catch (err) {
      toggleSaving(false);
      onApiError(err, 'Error saving comment');
    }
  };

  const handleDelete = async () => {
    try {
      toggleDeleting(true);
      await API.Notes.removeNoteComment(comment.id);
      dispatch(dispatchDeleteNoteComment({ noteCommentID: comment.id }));
      toggleDeleting(false);
    } catch (err) {
      toggleDeleting(false);
      onApiError(err, 'Error deleting comment');
    }
  };

  const renderContent = () => {
    const content = parseContent(comment);
    return isEditing ? (
      <NotesField
        isSaving={isSaving}
        className={classes.notesField}
        name="message"
        placeholder="New comment..."
        defaultValue={content}
        users={practiceUsers.map(({ user }) => user)}
        onSave={handleSave}
        onCancel={() => toggleEditing()}
      />
    ) : (
      <Note value={content} />
    );
  };

  return (
    <ListItem key={comment.id} sx={styles.comment} className={classes.comment}>
      <BlockHeader
        editable={editable}
        createdBy={comment.createdBy}
        createdAt={comment.createdAt}
        onEdit={() => toggleEditing(true)}
        onDelete={handleDelete}
      />
      {renderContent()}
    </ListItem>
  );
}
