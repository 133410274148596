'use client';
import { useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { Mention, MentionsInput } from 'react-mentions';
import { type UndecoratedUser } from '@witmetrics/api-client';
import Box from '@mui/material/Box';
import { renderUserName } from '@/utils/strings';
import { classes, styles } from './utils';

export type MentionTextFieldProps = {
  disabled?: boolean;
  autoFocus?: boolean;
  className?: string;
  placeholder?: string;
  value: string;
  users: UndecoratedUser[];
  onChange: (value: string) => void;
};

export default function MentionTextField({
  disabled,
  autoFocus,
  className,
  placeholder,
  value,
  users,
  onChange,
}: MentionTextFieldProps) {
  const suggestionsWrapperRef = useRef<HTMLDivElement | null>(null);

  return (
    <Box sx={styles.wrapper} className={twMerge(classes.wrapper, className)}>
      {/* @ts-ignore */}
      <MentionsInput
        allowSpaceInQuery
        allowSuggestionsAboveCursor
        disabled={disabled}
        autoFocus={autoFocus}
        className={classes.input}
        value={value}
        placeholder={placeholder}
        // @ts-ignore
        suggestionsPortalHost={suggestionsWrapperRef.current}
        onChange={(e) => onChange(e.target.value)}>
        {/* @ts-ignore */}
        <Mention
          appendSpaceOnAdd
          trigger="@"
          data={users.map((user) => ({
            id: user.id,
            display: renderUserName(user),
          }))}
          // NOTE: Giving extra space as a workaround for padding
          displayTransform={(id, display) => `  @${display} `}
          // @ts-ignore
          renderSuggestion={(suggestion) => (
            <div className={classes.suggestion}>{suggestion.display}</div>
          )}
        />
      </MentionsInput>
      <Box ref={suggestionsWrapperRef} sx={styles.suggestionsWrapper} />
    </Box>
  );
}
