import { useRef, useState } from 'react';
import { DropResult } from 'react-smooth-dnd';
import type {
  DecoratedLead,
  DecoratedUnisonProject,
} from '@witmetrics/api-client';
import { useAppState } from '@/providers/AppStateProvider';
import { useToggle } from '@/hooks/useToggle';
import Divider from '@/components/Divider';
import Dialog, {
  DefaultDialogActions,
  DialogBody,
  DialogTitle,
} from '@/components/Dialog';
import {
  useActiveAccount,
  useDispatch,
  usePracticeUsers,
} from '@/store/useStore';
import { addUnisonProject as dispatchAddUnisonProject } from '@/store/slices/unisonProjectsSlice';
import { addUnisonProjectFileCollection as dispatchAddUnisonProjectFileCollection } from '@/store/slices/unisonProjectFileCollectionsSlice';
import { addUnisonProjectNote as dispatchAddUnisonProjectNote } from '@/store/slices/unisonProjectNotesSlice';
import { setFileCollections as dispatchSetFileCollections } from '@/store/slices/fileCollectionsSlice';
import { setFileCollectionFiles as dispatchSetFileCollectionFiles } from '@/store/slices/fileCollectionFilesSlice';
import { setFiles as dispatchSetFiles } from '@/store/slices/filesSlice';
import { setFileVersions as dispatchSetFileVersions } from '@/store/slices/fileVersionsSlice';
import TextField from '@/components/TextField';
import MentionTextField from '@/components/MentionTextField';
import AddIcon from '@/icons/AddIcon';
import FileInput from '@/components/FileInput';
import {
  addToArray,
  arrayMove,
  hasMovedIndex,
  removeFromArray,
} from '@/utils/arrays';
import SelectedFilesList from './SelectedFilesList';
import { useLoadedFiles } from '@/providers/LoadedFilesProvider';
import { type FileType } from '@/constants/fileTypes';
import SearchableDropdown, {
  type SearchableDropdownOption,
} from '@/components/SearchableDropdown';
import { buildUserOption } from '@/utils/filters';
import { buildEmptyGroupedFiles } from '@/utils/files';
import { searchLeads } from '@/dialogs/ProjectDialog/utils';
import NewLeadDialog from '@/dialogs/NewLeadDialog';
import CloseIcon from '@/icons/CloseIcon';
import NoFilesWarningDialog from '@/dialogs/NoFilesWarningDialog';
import {
  buildSelectedFile,
  classes,
  createNewProject,
  groupByFileType,
  type GroupedFiles,
  hasSelectedFiles,
  isDisabled,
  type SelectedFile,
} from './utils';

type NewProjectDialogProps = {
  projectID?: number;
  onSave?: (project: DecoratedUnisonProject) => void;
  onClose: () => void;
};

export default function NewProjectDialog({
  projectID,
  onSave,
  onClose,
}: NewProjectDialogProps) {
  const dispatch = useDispatch();
  // NOTE: using a ref to handle stale state merging
  const selectedFilesRef = useRef<GroupedFiles>(buildEmptyGroupedFiles());
  const activeAccount = useActiveAccount();
  const practiceUsers = usePracticeUsers();
  const { onSelectProject, onApiError, onToggleUnloadWarning } = useAppState();
  const { addLoadedFiles } = useLoadedFiles();
  const [name, setName] = useState('');
  const [notes, setNotes] = useState('');
  const [initialFirstname, setInitialFirstname] = useState('');
  const [lead, setLead] = useState<SearchableDropdownOption<number> | null>(
    null
  );
  const [selectedFiles, setSelectedFiles] = useState<GroupedFiles>(
    buildEmptyGroupedFiles()
  );
  const [isLeadDialogOpen, toggleLeadDialog] = useToggle(false);
  const [isNameShown, toggleNameShown] = useToggle(false);
  const [isFileWarningShown, toggleFileWarning] = useToggle(false);
  const [isSaving, toggleSaving] = useToggle(false);

  const handleClose = () => {
    if (!isSaving) onClose();
  };

  const handleSave = async (ignoreFileCount?: boolean) => {
    try {
      if (!activeAccount || isSaving) return;
      if (!ignoreFileCount && !hasSelectedFiles(selectedFiles)) {
        return toggleFileWarning(true);
      }
      // TODO: handle updating instead of creating (i.e. `if (projectID){...}`)
      toggleSaving(true);
      onToggleUnloadWarning(true);
      const userID = lead?.value;
      const data = await createNewProject(
        {
          name: lead?.label || name,
          notes,
          userID,
          practiceID: activeAccount.accountID,
          selectedFiles,
        },
        handleChangeFile
      );
      onToggleUnloadWarning(false);
      dispatch(dispatchAddUnisonProject(data.unisonProject));
      dispatch(
        dispatchAddUnisonProjectFileCollection(data.unisonProjectFileCollection)
      );
      dispatch(dispatchSetFileCollections(data.fileCollections));
      dispatch(dispatchSetFiles(data.files));
      dispatch(dispatchSetFileVersions(data.fileVersions));
      dispatch(dispatchSetFileCollectionFiles(data.fileCollectionFiles));
      addLoadedFiles(data.loadedFiles);
      if (data.unisonProjectNote) {
        dispatch(dispatchAddUnisonProjectNote(data.unisonProjectNote));
      }
      if (onSave) onSave(data.unisonProject);
      if (!projectID) onSelectProject(data.unisonProject.id);
      toggleSaving(false);
      onClose();
    } catch (err) {
      toggleSaving(false);
      onApiError(err, 'Error creating case');
    }
  };

  const handleStartNewLead = (search: string) => {
    setInitialFirstname(search);
    toggleLeadDialog(true);
  };

  const handleSelectNewLead = (lead: DecoratedLead) => {
    setLead(buildUserOption(lead));
    toggleLeadDialog(false);
  };

  const updateSelectedFiles = (files: GroupedFiles) => {
    selectedFilesRef.current = files;
    setSelectedFiles(selectedFilesRef.current);
  };

  const handleSelectGroupFiles = (files: File[], fileType?: FileType) => {
    updateSelectedFiles(
      groupByFileType(
        selectedFilesRef.current,
        files.map((f) => buildSelectedFile(f, fileType))
      )
    );
  };

  const handleSelectFiles = (files: File[]) => {
    updateSelectedFiles(
      groupByFileType(
        selectedFilesRef.current,
        files.map((f) => buildSelectedFile(f))
      )
    );
  };

  const handleUpdateFileType = (
    selectedFile: SelectedFile,
    fileType: FileType
  ) => {
    // When done via the selector dropdown
    const newKey = fileType;
    const oldKey = selectedFile.fileType;
    updateSelectedFiles({
      ...selectedFilesRef.current,
      [newKey]: addToArray(selectedFilesRef.current[newKey], {
        ...selectedFile,
        fileType,
      }),
      [oldKey]: selectedFilesRef.current[oldKey].filter(
        (sf) => sf.uuid !== selectedFile.uuid
      ),
    });
  };

  const handleChangeFile = (selectedFile: SelectedFile) => {
    const key = selectedFile.fileType;
    updateSelectedFiles({
      ...selectedFilesRef.current,
      [key]: selectedFilesRef.current[key].map((sf) =>
        sf.uuid === selectedFile.uuid ? selectedFile : sf
      ),
    });
  };

  const handleMoveFile = (dropResult: DropResult, fileType: FileType) => {
    // When done via drag & drop
    const { removedIndex, addedIndex, payload } = dropResult;
    if (!hasMovedIndex(removedIndex, addedIndex)) return; // Nothing changed
    let file = { ...payload } as SelectedFile;
    let files = { ...selectedFilesRef.current };
    if (addedIndex !== null && removedIndex !== null) {
      // Item moved within the same step
      files[fileType] = arrayMove(files[fileType], removedIndex, addedIndex);
    } else if (addedIndex !== null) {
      // Item was added but not removed
      file.fileType = fileType;
      files[fileType] = addToArray(files[fileType], file, addedIndex);
    } else if (removedIndex !== null) {
      // Item was removed but not added
      files[fileType] = removeFromArray(files[fileType], removedIndex);
    }
    updateSelectedFiles(files);
  };

  const handleRemoveFile = (selectedFile: SelectedFile) => {
    const key = selectedFile.fileType;
    updateSelectedFiles({
      ...selectedFilesRef.current,
      [key]: selectedFilesRef.current[key].filter(
        (sf) => sf.uuid !== selectedFile.uuid
      ),
    });
  };

  const handleRemoveCaseName = () => {
    setName('');
    toggleNameShown(false);
  };

  return (
    <>
      <Dialog className={classes.dialog} onClose={handleClose}>
        <DialogTitle title="New Case" onClose={handleClose} />
        <DialogBody className={classes.body}>
          <div className={classes.wrapper}>
            <form className={classes.form}>
              <div className={classes.section}>
                <SearchableDropdown<'lead', number>
                  allowNew
                  autoFocus
                  disabled={isSaving}
                  className={classes.input}
                  name="lead"
                  label="Patient name"
                  newLabel="New Patient:"
                  value={lead}
                  initialOptions={lead ? [lead] : []}
                  onSearch={searchLeads}
                  onAddNew={handleStartNewLead}
                  onSelect={(update) => setLead(update.lead)}
                />
                {!isNameShown && (
                  <button
                    className={classes.addNameButton}
                    onClick={() => toggleNameShown(true)}>
                    <AddIcon className={classes.addNameButtonIcon} />
                    Case name/number
                  </button>
                )}
                {isNameShown && (
                  <div className={classes.nameWrapper}>
                    <TextField
                      disabled={isSaving}
                      className={classes.nameField}
                      name="name"
                      label="Case name"
                      value={name}
                      onChange={(update) => setName(update.name)}
                    />
                    <CloseIcon
                      className={classes.closeNameIcon}
                      onClick={handleRemoveCaseName}
                    />
                  </div>
                )}
              </div>
              <div className={classes.section}>
                <h3 className={classes.instructions}>Treatment Goals</h3>
                {!projectID && (
                  <MentionTextField
                    disabled={isSaving}
                    className={classes.goalsInput}
                    placeholder="Enter your goals for this treatment..."
                    value={notes}
                    users={practiceUsers.map(({ user }) => user)}
                    onChange={(val) => setNotes(val)}
                  />
                )}
              </div>
              <FileInput
                multiple
                disabled={isSaving}
                id="new-project-files"
                onSelect={handleSelectFiles}
              />
              <label htmlFor="new-project-files" className={classes.addButton}>
                <AddIcon className={classes.addIcon} />
                <span>Select file(s)</span>
              </label>
            </form>
            <Divider
              className={classes.columnDivider}
              orientation="vertical"
              variant="middle"
            />
            <div className={classes.filesWrapper}>
              {Object.keys(selectedFiles).length > 0 ? (
                <SelectedFilesList
                  isSaving={isSaving}
                  selectedFiles={selectedFiles}
                  onAddFiles={handleSelectGroupFiles}
                  onMoveFile={handleMoveFile}
                  onUpdateFile={handleUpdateFileType}
                  onRemoveFile={handleRemoveFile}
                />
              ) : (
                <div className={classes.emptyLabel}>No files selected.</div>
              )}
            </div>
          </div>
        </DialogBody>
        <DefaultDialogActions
          disabled={isDisabled(lead, name)}
          pending={isSaving}
          confirmLabel="Save"
          pendingLabel="Saving..."
          onCancel={handleClose}
          onConfirm={() => handleSave(false)}
        />
      </Dialog>
      {isLeadDialogOpen && (
        <NewLeadDialog
          initialFirstname={initialFirstname}
          onClose={() => toggleLeadDialog(false)}
          onSelect={handleSelectNewLead}
        />
      )}
      {isFileWarningShown && (
        <NoFilesWarningDialog
          onContinue={() => {
            toggleFileWarning(false);
            handleSave(true);
          }}
          onClose={() => toggleFileWarning(false)}
        />
      )}
    </>
  );
}
