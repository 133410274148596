import { Dictionary } from '@/types';
import { ParsedProperty } from '@/types/properties';

export const classes = {
  dialog: 'w-[98vw] max-w-[400px] min-w-[250px]',
  input: 'w-full mb-4',
  section: 'w-full py-3',
  selectDropdown: 'w-full',
  body: 'pt-0 pb-6 w-full min-h-0 overflow-auto',
  optional: 'my-4 text-base text-grey-8 font-medium',
};

export function formatSelectValue(label: string) {
  return { value: label, label };
}

export function buildNewLeadProperties({
  practiceProperties,
  phone,
  enquiryStatus,
}: {
  practiceProperties: Dictionary<ParsedProperty>;
  phone: string;
  enquiryStatus: {
    value: string;
  };
}) {
  const getID = (key: keyof typeof practiceProperties) =>
    practiceProperties[key].id;
  let leadProperties = [];
  if (phone !== '') {
    leadProperties.push({ propertyID: getID('phone'), value: phone });
  }
  if (enquiryStatus.value !== '') {
    leadProperties.push({
      propertyID: getID('enquiryStatus'),
      value: enquiryStatus.value,
    });
  }
  return leadProperties;
}
