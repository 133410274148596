'use client';
import { useEffect, useState } from 'react';
import {
  useDispatch,
  useFileCollectionsFromParentID,
  useFilesFromFileCollectionID,
  useSequencesFromFileCollectionID,
} from '@/store/useStore';
import { addUnisonProjectFileCollection as dispatchAddUnisonProjectFileCollection } from '@/store/slices/unisonProjectFileCollectionsSlice';
import { setFileCollectionFiles as dispatchSetFileCollectionFiles } from '@/store/slices/fileCollectionFilesSlice';
import { setFileCollectionSequences as dispatchSetFileCollectionSequences } from '@/store/slices/fileCollectionSequencesSlice';
import { setFileCollections as dispatchSetFileCollections } from '@/store/slices/fileCollectionsSlice';
import { useAppState } from '@/providers/AppStateProvider';
import { useToggle } from '@/hooks/useToggle';
import { fetchCollectionContents } from '@/utils/files';
import FileCollectionCrumbs, {
  type FileCollectionPath,
} from '@/components/FileCollectionCrumbs';
import FileCollection from '@/components/FileCollection';
import FileCollectionHeader from '@/components/FileCollectionHeader';
import Card from '@/components/Card';
import Section from '@/overlays/ProjectOverlay/Section';
import { usePath } from '@/hooks/usePath';
import { classes, fetchProjectData, filterCollectionItems } from './utils';

export type FilesSectionProps = {
  isFetchingData: boolean;
};

export default function FilesSection({ isFetchingData }: FilesSectionProps) {
  const dispatch = useDispatch();
  const { onApiError, activeProjectID } = useAppState();
  const { searchParams, pushParams, removeParams } = usePath();
  const [baseCollectionID, setBaseCollectionID] = useState<number>();
  const [activeCollectionID, setActiveCollectionID] = useState<number>();
  const [search, setSearch] = useState('');
  const [crumbs, setCrumbs] = useState<FileCollectionPath[]>([]);
  const [isFetchingFiles, toggleFetchingFiles] = useToggle(true);
  const fileCollections = useFileCollectionsFromParentID(activeCollectionID);
  const files = useFilesFromFileCollectionID(activeCollectionID);
  const sequences = useSequencesFromFileCollectionID(activeCollectionID);

  useEffect(() => {
    fetchData();
  }, [activeCollectionID]);

  useEffect(() => {
    if (searchParams.get('activeCollectionID')) {
      const collectionID = parseInt(searchParams.get('activeCollectionID')!);
      if (collectionID !== activeCollectionID) {
        handleOpenFileCollection(collectionID);
      }
    } else if (activeCollectionID && baseCollectionID) {
      setActiveCollectionID(baseCollectionID);
    }
  }, [searchParams]);

  const handleFetchProjectFileCollection = async () => {
    try {
      if (!activeProjectID) return console.warn('Missing activeProjectID');
      // TODO: pagination -- file & collection ID arrays are all based on Redux state
      toggleFetchingFiles(true);
      const {
        unisonProjectFileCollection,
        fileCollectionFiles,
        fileCollectionSequences,
      } = await fetchProjectData(activeProjectID);
      dispatch(
        dispatchAddUnisonProjectFileCollection(unisonProjectFileCollection)
      );
      dispatch(dispatchSetFileCollectionFiles(fileCollectionFiles));
      dispatch(dispatchSetFileCollectionSequences(fileCollectionSequences));
      setBaseCollectionID(unisonProjectFileCollection.fileCollectionID);
      setActiveCollectionID(unisonProjectFileCollection.fileCollectionID);
      setCrumbs([]);
    } catch (err) {
      toggleFetchingFiles(false);
      onApiError(err, 'Error fetching file collection', () => fetchData());
    }
  };

  const handleFetchCollectionContents = async () => {
    try {
      // TODO: pagination
      toggleFetchingFiles(true);
      const data = await fetchCollectionContents(activeCollectionID!, {
        page: 1,
        pageSize: 100,
      });
      dispatch(dispatchSetFileCollections(data.fileCollections.results));
      dispatch(dispatchSetFileCollectionFiles(data.files.results));
      // Exclude the root directory (it's marked by the `Home` crumb)
      setCrumbs(data.path.filter((crumb) => crumb.id !== baseCollectionID));
      toggleFetchingFiles(false);
    } catch (err) {
      toggleFetchingFiles(false);
      onApiError(err, 'Error fetching file collection contents', () =>
        fetchData()
      );
    }
  };

  const fetchData = () => {
    return activeCollectionID
      ? handleFetchCollectionContents()
      : handleFetchProjectFileCollection();
  };

  const handleOpenFileCollection = (fileCollectionID: number | undefined) => {
    // Should never be undefined in this instance
    setActiveCollectionID(fileCollectionID);
    setSearch('');
    if (fileCollectionID === baseCollectionID) {
      removeParams(['activeCollectionID']);
    } else {
      pushParams({ activeCollectionID: fileCollectionID });
    }
  };

  return (
    <Section>
      <FileCollectionHeader
        isFetchingData={isFetchingData || isFetchingFiles}
        fileCollectionID={activeCollectionID}
        search={search}
        onSearch={setSearch}
      />
      <Card className={classes.card}>
        <FileCollectionCrumbs
          className={classes.breadcrumbs}
          homeValue={baseCollectionID}
          crumbs={crumbs}
          onSelect={handleOpenFileCollection}
        />
        <FileCollection
          isFetchingData={isFetchingData || isFetchingFiles}
          parentFileCollectionID={activeCollectionID}
          fileCollections={filterCollectionItems(fileCollections, search)}
          files={filterCollectionItems(files, search)}
          sequences={filterCollectionItems(sequences, search)}
          onOpenFileCollection={handleOpenFileCollection}
        />
      </Card>
    </Section>
  );
}
