import { shadows, theme } from '@/utils/theme';

export const classes = {
  wrapper: 'p-2 bg-white rounded-xl',
  input: 'mention-input text-base text-grey-8',
  suggestion:
    'transition-colors duration-100 text-grey-8 font-normal py-2 px-4 mb-2 rounded-lg bg-white hover:bg-grey-2',
};

export const styles = {
  wrapper: {
    '& .mention-input': {
      width: '100%',
      fontSize: '0.875rem', // text-sm
    },
    '& .mention-input__input': {
      outline: 'none',
    },
    '& strong': {
      backgroundColor: theme.palette['grey-3'].main,
      padding: '0.25rem 0', // py-1
      borderRadius: '0.5rem', // rounded-lg
    },
  },
  suggestionsWrapper: {
    '& .mention-input__suggestions': {
      borderRadius: '1rem', // rounded-2xl
      padding: '0.5rem', // p-2
      boxShadow: shadows.dialog,
    },
  },
};
