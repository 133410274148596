import {
  DecoratedFileCollection,
  DecoratedFileCollectionFile,
  UndecoratedFile,
  UndecoratedFileVersion,
  type UndecoratedUnisonProject,
} from '@witmetrics/api-client';
import { API } from '@/api';
import { isBlank } from '@/utils/strings';
import { FILE_TYPES, type FileType } from '@/constants/fileTypes';
import { generateUUID } from '@/utils/uuid';
import {
  getFileType,
  isScan,
  isViewableNonScan,
  uploadFile,
} from '@/utils/files';
import { UPLOAD_STATUSES, type UploadStatus } from '@/constants/uploadStatuses';
import { SearchableDropdownOption } from '@/components/SearchableDropdown';
import { LoadedFile } from '@/providers/LoadedFilesProvider';
import { loadScan } from '@/utils/scans';

const { IDLE, IN_PROGRESS, DONE, ERROR } = UPLOAD_STATUSES;

export const classes = {
  dialog:
    'w-[98vw] max-w-[1200px] min-w-[400px] min-h-[450px] max-h-[850px] h-[90vh] flex flex-col',
  body: 'grow pt-0 min-h-0',
  wrapper: 'flex justify-between h-full',
  form: 'flex flex-col w-[400px] h-full overflow-y-auto pt-2',
  section: 'mb-12',
  instructions: 'text-grey-8 text-base font-semibold mb-2 ml-2',
  filesWrapper: 'grow flex flex-col h-full overflow-y-auto pr-4 pt-2',
  input: 'w-full mb-4',
  goalsInput: 'w-full mb-4 min-h-24 border border-grey-5 px-3',
  nameWrapper: 'w-full mb-4 flex items-center',
  addNameButton:
    'text-grey-6 hover:text-grey-7 bg-grey-2 hover:bg-grey-3 py-1 px-2 rounded-xl transition-colors flex items-center justify-center w-full text-base',
  addNameButtonIcon: 'mr-2 text-xl',
  closeNameIcon:
    'ml-2 text-grey-6 hover:text-grey-7 transition-colors cursor-pointer',
  nameField: 'grow',
  columnDivider: 'mx-8 my-0',
  addIcon: 'mr-3 text-2xl',
  addButton:
    'mt-4 bg-purple-2 text-white cursor-pointer px-6 py-2 rounded-xl flex items-center justify-center hover:shadow-lg transition-all',
  iconButton: 'text-grey-6 hover:text-grey-7 transition-colors cursor-pointer',
  emptyLabel: 'text-grey-6 text-xl text-center mt-8',
};

export type SelectedFile = {
  id?: number; // For existing files
  uuid: string; // To keep track of all files
  uploadStatus: UploadStatus;
  fileType: FileType;
  file: File;
};

export type GroupedFiles = { [key in FileType]: SelectedFile[] };

export function groupByFileType(
  groupedFiles: GroupedFiles,
  selectedFiles: SelectedFile[]
): GroupedFiles {
  let result = { ...groupedFiles };
  selectedFiles.forEach((sf) => {
    result[sf.fileType].push(sf);
  });
  return result;
}

export function hasSelectedFiles(groupedFiles: GroupedFiles) {
  return Object.values(groupedFiles).some((files) => files.length > 0);
}

export function isDisabled(
  lead: SearchableDropdownOption<number> | null,
  name: string
) {
  return !lead && isBlank(name);
}

export function buildSelectedFile(
  file: File,
  fileType?: FileType
): SelectedFile {
  return {
    uuid: generateUUID(),
    uploadStatus: IDLE,
    fileType: fileType || getFileType(file.name),
    file,
  };
}

type NewProjectData = {
  name: string;
  notes: string;
  userID?: number;
  practiceID: number;
  selectedFiles: GroupedFiles;
};

export async function createNewProject(
  { name, notes, userID, selectedFiles }: NewProjectData,
  onUploadProgress: (selectedFile: SelectedFile) => void
) {
  // Create a new project
  let unisonProject = await createProject(name, userID);
  // Create file collections and optional notes
  const [unisonProjectNote, collectionData] = await Promise.all([
    createProjectNote(unisonProject.id, notes),
    createProjectFileCollection(unisonProject, selectedFiles, onUploadProgress),
  ]);
  return {
    unisonProject,
    unisonProjectNote,
    ...collectionData,
  };
}

function createProject(name: string, userID?: number) {
  return API.UnisonProjects.createNewProject({ name, userID, description: '' });
}

async function createProjectFileCollection(
  project: UndecoratedUnisonProject,
  selectedFiles: GroupedFiles,
  onUploadProgress: (selectedFile: SelectedFile) => void
) {
  // Create a container file collection for the project
  const parentCollection = await createFileCollection(
    project.name,
    project.practiceID
  );
  const unisonProjectFileCollection =
    await API.UnisonProjects.addFileCollectionToUnisonProject(
      project.id,
      parentCollection.id
    );
  const uploads = await Promise.all(
    (Object.keys(selectedFiles) as FileType[]).map((fileType) =>
      uploadFileCollection(
        parentCollection,
        fileType,
        selectedFiles[fileType],
        onUploadProgress
      )
    )
  );
  return {
    unisonProjectFileCollection: {
      ...unisonProjectFileCollection,
      fileCollection: parentCollection,
    },
    fileCollections: [
      parentCollection,
      ...uploads.map((u) => u.fileCollection),
    ],
    ...parseUploadArray(uploads.flatMap((u) => u.files)),
  };
}

async function createProjectNote(projectID: number, content: string) {
  if (isBlank(content)) return null;
  const note = await API.Notes.createNewNote({ content });
  return API.UnisonProjects.addNoteToUnisonProject(projectID, note.id);
}

async function createFileCollection(
  name: string,
  practiceID: number,
  parentFileCollectionID?: number
) {
  const collection = await API.FileCollections.createNewFileCollection({
    name,
    practiceID,
    parentFileCollectionID,
  });
  return {
    ...collection,
    childFileCollections: 0,
    childFiles: 0,
  } as DecoratedFileCollection;
}

async function uploadFileCollection(
  parentFileCollection: DecoratedFileCollection,
  fileType: FileType,
  selectedFiles: SelectedFile[],
  onUploadProgress: (selectedFile: SelectedFile) => void
) {
  const { id, practiceID } = parentFileCollection;
  // Create a file collection for the file type
  const fileCollection = await createFileCollection(
    FILE_TYPES[fileType].label,
    practiceID,
    id
  );
  // Upload all the files
  const files = await Promise.all(
    selectedFiles.map((sf) =>
      uploadFileToCollection(
        fileCollection.id,
        practiceID,
        sf,
        onUploadProgress
      )
    )
  );
  return {
    files,
    fileCollection: {
      ...fileCollection,
      childFileCollections: 0,
      childFiles: files.length,
    } as DecoratedFileCollection,
  };
}

async function uploadFileToCollection(
  collectionID: number,
  practiceID: number,
  selectedFile: SelectedFile,
  onUploadProgress: (selectedFile: SelectedFile) => void
) {
  try {
    onUploadProgress({ ...selectedFile, uploadStatus: IN_PROGRESS });
    const { file, fileVersion } = await uploadFile(
      selectedFile.file,
      practiceID,
      FILE_TYPES[selectedFile.fileType].id
    );
    const fileCollectionFile = await API.FileCollections.addFileCollectionFile(
      collectionID,
      file.id
    );
    // Load file locally, no need to download right after uploading
    let loadedFile: LoadedFile | undefined;
    const buffer = await selectedFile.file.arrayBuffer();
    if (isScan(fileVersion.name)) {
      loadedFile = loadScan(fileVersion.name, buffer)!;
    } else if (isViewableNonScan(fileVersion.name)) {
      loadedFile = URL.createObjectURL(new Blob([buffer]));
    }
    onUploadProgress({ ...selectedFile, uploadStatus: DONE });
    return { file, fileVersion, fileCollectionFile, loadedFile };
  } catch (err) {
    onUploadProgress({ ...selectedFile, uploadStatus: ERROR });
    throw err;
  }
}

function parseUploadArray(
  uploads: Awaited<ReturnType<typeof uploadFileToCollection>>[]
) {
  let files: UndecoratedFile[] = [];
  let fileVersions: UndecoratedFileVersion[] = [];
  let fileCollectionFiles: DecoratedFileCollectionFile[] = [];
  let loadedFiles: { [key: string]: LoadedFile } = {};
  uploads.flat().forEach((u) => {
    files.push(u.file);
    fileVersions.push(u.fileVersion);
    fileCollectionFiles.push(u.fileCollectionFile);
    if (u.loadedFile) loadedFiles[u.file.id] = u.loadedFile;
  });
  return { files, fileVersions, fileCollectionFiles, loadedFiles };
}
