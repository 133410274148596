import { deleteUnisonProject as dispatchDeleteUnisonProject } from '@/store/slices/unisonProjectsSlice';
import Dialog, {
  DefaultDialogActions,
  DialogBody,
  DialogTitle,
} from '@/components/Dialog';
import { API } from '@/api';
import { useDispatch, useUnisonProject } from '@/store/useStore';
import { showSuccessToaster } from '@/utils/toasters';
import { useAppState } from '@/providers/AppStateProvider';
import { useToggle } from '@/hooks/useToggle';
import { classes } from './utils';

export type DeleteProjectDialogProps = {
  projectID: number;
  onDelete?: (projectID: number) => void;
  onClose: () => void;
};

export default function DeleteProjectDialog({
  projectID,
  onDelete,
  onClose,
}: DeleteProjectDialogProps) {
  const dispatch = useDispatch();
  const { onApiError } = useAppState();
  const project = useUnisonProject(projectID);
  const [isDeleting, toggleDeleting] = useToggle(false);

  const handleDelete = async () => {
    try {
      toggleDeleting(true);
      await API.UnisonProjects.removeUnisonProject(projectID);
      dispatch(dispatchDeleteUnisonProject({ projectID }));
      if (onDelete) onDelete(projectID);
      showSuccessToaster('Project deleted');
      toggleDeleting(false);
      onClose();
    } catch (err) {
      toggleDeleting(false);
      onApiError(err, 'Error deleting case', () => {
        handleDelete();
      });
    }
  };

  return (
    <Dialog className={classes.dialog} onClose={onClose}>
      <DialogTitle title="Delete project" onClose={onClose} />
      <DialogBody className={classes.body}>
        {`Are you sure you want to remove "${project!.name}"?`}
        <br />
        All data associated with this project will be deleted.
        <br />
        This action cannot be undone.
      </DialogBody>
      <DefaultDialogActions
        disabled={isDeleting}
        pending={isDeleting}
        confirmLabel="Delete"
        pendingLabel="Deleting..."
        onCancel={onClose}
        onConfirm={handleDelete}
      />
    </Dialog>
  );
}
