export const classes = {
  wrapper: 'h-screen grow min-w-[350px] overflow-x-auto px-3',
  collapsed: '',
  expanded: '',
  // collapsed: 'w-[1375px]', // 1000px + the spare tabs column width (375px)
  // expanded: 'w-[1000px]',
  tabsBlock: 'w-full flex flex-col flex-1 h-full',
  tabBody: 'flex-1 min-h-0 overflow-auto',
  buttonsWrapper:
    'absolute top-0 right-0 mx-2 mt-1 mb-2 z-10 flex items-center',
  icon: 'text-grey-7',
  name: 'mr-4 text-grey-8 font-semibold text-lg',
};

export type ProjectOverlayTab = 'overview' | 'checklists' | 'files';
