import { API } from '@/api';

export const classes = {
  wrapper: 'h-full w-full px-2 py-8',
  header: 'flex items-center justify-start w-full px-2 pb-4',
  title: 'mr-2',
  breadcrumbs: 'pt-0 pb-4',
  emptyWrapper: 'w-full h-full flex flex-col items-center justify-center pt-12',
  emptyTitle: 'text-grey-7 text-xl',
  emptyMessage: 'text-grey-7 text-base pt-4',
  filesWrapper: 'flex flex-wrap gap-6 w-full pt-4 px-2',
  skeleton: 'h-32 w-44 rounded-lg',
  card: 'flex flex-col',
};

export const styles = {
  skeleton: (delay: number) => ({
    '&:after': {
      animationDelay: `${delay}s`,
      animationDuration: '1.2s',
    },
  }),
};

export async function fetchProjectData(unisonProjectID: number) {
  /*
    Each project starts with a top-level file collection
    that's defined by a file_collection w/out a parent_file_collection_id
   */
  const unisonProjectFileCollection =
    await API.UnisonProjects.fetchUnisonProjectFileCollection(unisonProjectID);
  const { fileCollectionID } = unisonProjectFileCollection;
  const [fileCollectionFiles, fileCollectionSequences] = await Promise.all([
    fetchFileCollectionFiles(fileCollectionID),
    fetchFileCollectionSequences(fileCollectionID),
  ]);
  return {
    unisonProjectFileCollection,
    fileCollectionFiles: fileCollectionFiles.results,
    fileCollectionSequences: fileCollectionSequences.results,
  };
}

function fetchFileCollectionFiles(fileCollectionID: number) {
  return API.FileCollections.fetchFileCollectionFiles(fileCollectionID, {
    filters: [],
    pagination: {
      page: 1,
      pageSize: 100, // NOTE: Should be enough for now
    },
  });
}

function fetchFileCollectionSequences(fileCollectionID: number) {
  return API.FileCollections.fetchFileCollectionSequences(fileCollectionID, {
    filters: [],
    pagination: {
      page: 1,
      pageSize: 100, // NOTE: Should be enough for now
    },
  });
}

export function filterCollectionItems<
  T extends { name: string; description?: string | null },
>(items: T[] | null, searchQuery: string) {
  const search = searchQuery.toLowerCase().trim();
  if (search.length === 0 || !items) return items;
  return items.filter((item) => {
    return `${item.name} ${item.description || ''}`
      .toLowerCase()
      .includes(search);
  });
}
