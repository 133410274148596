'use client';
import {
  type ChangeEvent,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react';
import { twMerge } from 'tailwind-merge';
import Dialog from '@mui/material/Dialog';
import InputBase from '@mui/material/InputBase';
import Slide, { type SlideProps } from '@mui/material/Slide';
import Button from '@/components/Button';
import ResultsList from './ResultsList';
import { useAppState } from '@/providers/AppStateProvider';
import {
  classes,
  fetchMatchingProjects,
  fetchRecentProjects,
  type SearchResults,
} from './utils';

export type SearchDialogProps = {
  onClose: () => void;
};

const Transition = forwardRef<any, SlideProps>((props, ref) => {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function SearchDialog({ onClose }: SearchDialogProps) {
  const timestampRef = useRef(Date.now());
  const inputRef = useRef<HTMLInputElement>(null);
  const {
    onApiError,
    onSelectProject,
    recentProjectIDs,
    onClearRecentProjects,
  } = useAppState();
  const [search, setSearch] = useState('');
  const [suggestions, setSuggestions] = useState<SearchResults>([]);
  const [recentProjects, setRecentProjects] = useState<SearchResults>([]);

  useEffect(() => {
    handleFetchRecentProjects();
  }, [recentProjectIDs]);

  const handleFetchRecentProjects = async () => {
    if (recentProjectIDs.length === 0) return setRecentProjects([]);
    const projects = await fetchRecentProjects(recentProjectIDs);
    setRecentProjects(projects);
  };

  const handleClearRecentProjects = () => {
    onClearRecentProjects();
    if (inputRef.current) inputRef.current.focus();
  };

  const handleSelect = (projectID: number) => {
    onSelectProject(projectID);
    onClose();
  };

  const handleSearch = async (e: ChangeEvent<HTMLInputElement>) => {
    const newSearch = e.target.value;
    const query = newSearch.toLowerCase().trim();
    let searchSuggestions: SearchResults = [];
    setSearch(newSearch);
    if (query.length > 0) {
      try {
        const results = await fetchMatchingProjects(query);
        // Prevent overwriting newer responses with slower previous ones
        if (results.timestamp > timestampRef.current) {
          searchSuggestions = results.unisonProjects;
        } else {
          searchSuggestions = suggestions;
        }
        timestampRef.current = results.timestamp;
      } catch (err) {
        onApiError(err, 'Error finding projects');
      }
    }
    setSuggestions(searchSuggestions);
  };

  const renderSuggestions = () => {
    const showRecent = search.length === 0 && recentProjects.length > 0;
    const projects = showRecent ? recentProjects : suggestions;
    return (
      projects?.length > 0 && (
        <ResultsList
          className={twMerge(
            classes.resultsList,
            showRecent && classes.recentList
          )}
          results={projects}
          onSelect={handleSelect}
        />
      )
    );
  };

  return (
    <Dialog
      open
      keepMounted
      disableRestoreFocus
      maxWidth={false}
      TransitionComponent={Transition}
      PaperProps={{ className: classes.paper }}
      onClose={onClose}>
      <div className={classes.wrapper}>
        <InputBase
          ref={inputRef}
          autoFocus
          autoComplete="off"
          className={classes.inputBase}
          name="projects-search"
          placeholder="Search for projects by patient, name, or description..."
          type="text"
          value={search}
          onChange={handleSearch}
        />
        {search.length === 0 && recentProjects.length > 0 && (
          <div className={classes.recentlyViewedWrapper}>
            <div className={classes.recentlyViewed}>Recently viewed</div>
            <Button
              variant="text"
              tabIndex={-1 /* Tab straight into projects list */}
              textClasses={classes.clearButton}
              onClick={handleClearRecentProjects}>
              Clear
            </Button>
          </div>
        )}
        {renderSuggestions()}
      </div>
    </Dialog>
  );
}
