import { twMerge } from 'tailwind-merge';
import { isString } from '@/utils/strings';
import { classes, parseMentions } from './utils';

export type NoteProps = {
  className?: string;
  value: string;
};

export default function Note({ value, className }: NoteProps) {
  const mentions = parseMentions(value);
  return (
    <p
      className={twMerge(
        classes.wrapper,
        mentions.length > 1 && classes.mentionsWrapper,
        className
      )}>
      {mentions.map((segment, i) =>
        isString(segment) ? (
          <span key={i}>{segment}</span>
        ) : (
          <span key={i} className={classes.mention}>
            {segment.label}
          </span>
        )
      )}
    </p>
  );
}
