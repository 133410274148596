import { useDispatch, usePracticeUsers } from '@/store/useStore';
import { API } from '@/api';
import { useAppState } from '@/providers/AppStateProvider';
import { useToggle } from '@/hooks/useToggle';
import { addNoteComment } from '@/store/slices/noteCommentsSlice';
import NotesField from '../../../../NotesField';
import { classes, styles } from './utils';

export type NewCommentProps = {
  noteID: number;
};

export default function NewComment({ noteID }: NewCommentProps) {
  const dispatch = useDispatch();
  const { onApiError } = useAppState();
  const practiceUsers = usePracticeUsers();
  const [isEditing, toggleEditing] = useToggle(false);
  const [isSaving, toggleSaving] = useToggle(false);

  const handleSave = async ({ message }: { message: string }) => {
    try {
      toggleSaving(true);
      const comment = await API.Notes.createNewNoteComment({ noteID, message });
      dispatch(addNoteComment(comment));
      toggleSaving(false);
      toggleEditing(false);
    } catch (err) {
      toggleSaving(false);
      onApiError(err, 'Error saving comment');
    }
  };

  return isEditing ? (
    <NotesField
      isSaving={isSaving}
      name="message"
      placeholder="New comment..."
      users={practiceUsers.map(({ user }) => user)}
      onSave={handleSave}
      onCancel={() => toggleEditing()}
    />
  ) : (
    <button
      className={classes.placeholder}
      style={styles.placeholder}
      onClick={() => toggleEditing()}>
      Add a comment...
    </button>
  );
}
