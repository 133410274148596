import { type MouseEvent, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { type UndecoratedUser } from '@witmetrics/api-client';
import Button from '@/components/Button';
import Card from '@/components/Card';
import MentionTextField from '@/components/MentionTextField';
import { classes } from './utils';

export type NotesFieldProps<N extends string> = {
  className?: string;
  isSaving: boolean;
  name: N;
  placeholder?: string;
  defaultValue?: string;
  users: UndecoratedUser[];
  onSave: (value: Record<N, string>) => void;
  onCancel: () => void;
};

export default function NotesField<N extends string>({
  className = '',
  isSaving,
  name,
  placeholder = 'New note...',
  defaultValue,
  users,
  onSave,
  onCancel,
}: NotesFieldProps<N>) {
  const [value, setValue] = useState(defaultValue || '');

  const handleSave = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSave({ [name]: value } as Record<N, string>);
  };

  return (
    <Card className={twMerge(classes.card, className)}>
      <MentionTextField
        autoFocus
        disabled={isSaving}
        value={value}
        placeholder={placeholder}
        users={users}
        onChange={(val) => setValue(val)}
      />
      <div className={classes.buttonsWrapper}>
        <Button
          variant="text"
          className={classes.button}
          textClasses={classes.cancel}
          onClick={onCancel}>
          Cancel
        </Button>
        <Button
          disabled={isSaving || value.trim().length === 0}
          className={classes.button}
          onClick={handleSave}>
          {isSaving ? 'Saving...' : 'Save'}
        </Button>
      </div>
    </Card>
  );
}
