import type {
  DecoratedUnisonProject,
  UndecoratedLead,
} from '@witmetrics/api-client';
import List, { type ListProps } from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { isBlank, renderUserName } from '@/utils/strings';
import { classes, styles } from './utils';

export interface ResultsListProps
  extends Omit<ListProps, 'results' | 'onSelect'> {
  onSelect: (id: number) => void;
  results?: DecoratedUnisonProject[];
}

export default function ResultsList({
  results = [],
  onSelect,
  ...props
}: ResultsListProps) {
  const renderDescription = (description: string, user?: UndecoratedLead) => {
    if (!user || !isBlank(description)) return description;
    return `${user.externalRef ? `${user.externalRef} - ` : ''}${renderUserName(user)}`;
  };

  return (
    <List {...props}>
      {results.map(({ id, name, description, user }) => {
        return (
          <ListItem key={id} disablePadding>
            <ListItemButton
              sx={styles.listItemButton}
              className={classes.listItemButton}
              onClick={() => onSelect(id)}>
              <ListItemText
                primary={name}
                secondary={renderDescription(description, user)}
              />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
}
