export const classes = {
  wrapper: 'w-full flex justify-start items-start pt-6',
  textWrapper: 'flex flex-col grow items-center justify-start',
  header: 'font-bold text-xl text-grey-8',
  status: 'mb-2',
  icon: 'text-3xl mx-3 text-grey-7 cursor-pointer',
  person: 'mr-4',
  nameWrapper: 'text-start',
  name: 'text-base text-grey-8',
  externalRef: 'text-sm text-grey-6 font-medium',
  button:
    'cursor-pointer text-grey-8 flex items-center justify-between hover:bg-grey-3 rounded-xl px-4 py-2',
};

export const styles = {
  collapseIcon: (isCollapsed?: boolean) => ({
    transform: `rotateY(${isCollapsed ? '180deg' : '0deg'})`,
    transition: 'transform 0.15s',
  }),
};
